
import { useDelivery } from '@/composables/useDelivery'
import { useConfirm } from '@/composables/useConfirm'
import { useNotifications } from '@/composables/useNotifications'
import { useRoute, useRouter } from 'vue-router'
import {
    EDeliveryActionHeadings as EHeadings,
    EDeliveryActions as EActions,
} from '@/enums/delivery'
import InputAmount from '@/components/InputAmount.vue'
import { defineComponent, watch, onMounted, computed, ref } from 'vue'
import AIcon from '@/components/global/AIcon.vue'
import MCommentPreview from '@/components/MCommentPreview.vue'
import { IDeliveryProduct, ITempComment } from '@/interfaces/IDelivery'
import localforage from 'localforage'

export default defineComponent({
    name: 'DeliveryDetailsCourier',
    components: {
        AIcon,
        InputAmount,
        MCommentPreview,
    },
    setup() {
        const activeState = ref('delivery-details')

        const {
            fetchDelivery,
            currentDelivery: delivery,
            updateDeliveryStage,
            actions,
            isLoading,
            setDeliveryProduct,
            getProductQuantity: productQuantity,
            sendDeliveryReturns,
            fetchSavedDeliveryReturns,
        } = useDelivery()
        const router = useRouter()
        const route = useRoute()
        const { initConfirm } = useConfirm()
        const { setNotification } = useNotifications()
        const isDeliveryReturnsSaved = ref(false)

        const isWarehouseNote = computed(() => delivery.value?.noteWarehouse)
        const isCourierNote = computed(() => delivery.value?.noteDelivery)
        const isManagerNote = computed(() => delivery.value?.noteCarWash)

        const confirmAccept = async () => {
            if (isDeliveryReturnsSaved.value) {
                await sendDeliveryReturns()
            }
            await updateDeliveryStage(+route.params.id)
            await localforage.removeItem('tempComment')
            await localforage.removeItem('currentDeliveryProducts')
            router.push({
                name: 'CarWashDetailsCourier',
                params: {
                    deliveryId: +route.params.id,
                    carWashId: delivery.value?.destinationWarehouseDetails[0].id,
                },
            })
        }

        const confirmReject = async () => {
            if (isDeliveryReturnsSaved.value) {
                await sendDeliveryReturns()
            }
            await updateDeliveryStage(+route.params.id)
            await localforage.removeItem('tempComment')
            await localforage.removeItem('currentDeliveryProducts')
            setActiveAction(EActions.Details)
        }

        const activeAction = computed(() => {
            return actions.find(el => el.action === activeState.value)
        })

        const isAcceptDisabled = computed(() => {
            return (
                delivery.value?.deliveryStage[0].stageNumber === 3 && !delivery.value?.noteDelivery
            )
        })

        const isLoadOrUnload = computed(() => {
            return (
                activeAction.value?.action === EActions.Load ||
                activeAction.value?.action === EActions.Unload
            )
        })

        const setActiveAction = (action: string) => {
            activeState.value = action
        }

        const isEditActive = computed(() => {
            return (
                activeAction.value?.action === EActions.Load ||
                activeAction.value?.action === EActions.Unload
            )
        })

        const changeDeliveryStatus = async (stageId: number) => {
            if (stageId === 4) {
                if (!delivery.value?.noteDelivery) {
                    setNotification('Wymagany komentarz')
                } else {
                    initConfirm('Czy chcesz uzupełnić zbiornik?', confirmAccept, confirmReject)
                }
            } else {
                await updateDeliveryStage(+route.params.id)
                setActiveAction(EActions.Details)
            }
        }

        const changeProductAmount = (product: IDeliveryProduct, quantity: any) => {
            setDeliveryProduct(product.productId, quantity)
            localforage.setItem('currentDeliveryProducts', {
                deliveryId: +route.params.id,
                products: JSON.parse(JSON.stringify(delivery.value?.products)),
            })
        }

        const addComment = async () => {
            await saveCurrentAction()
            router.push({
                name: 'AddCommentCourier',
                params: { id: route.params.id },
            })
        }

        const showAmountSymbol = () => {
            if (
                activeAction.value?.action === 'delivery-unload' ||
                activeAction.value?.action === 'delivery-load'
            ) {
                return true
            } else {
                return false
            }
        }

        const checkIfSavedReturnsExists = async () => {
            const savedReturns = await fetchSavedDeliveryReturns()
            if (savedReturns) {
                isDeliveryReturnsSaved.value = true
            } else {
                isDeliveryReturnsSaved.value = false
            }
        }

        const saveCurrentAction = async () => {
            const deliveryActiveAction = {
                ...activeAction.value,
                currentDeliveryId: route.params.id,
            }
            await localforage.setItem('deliveryActiveAction', deliveryActiveAction)
        }

        watch(route, async routeValue => {
            if (routeValue.name === 'DeliveryDetailsCourier') {
                await fetchDelivery(+route.params.id)
            }
        })

        onMounted(async () => {
            await fetchDelivery(+route.params.id)
            const savedProducts: any | null = await localforage.getItem('currentDeliveryProducts')
            if (delivery.value && savedProducts) {
                delivery.value.products = savedProducts.products
            }
            const tempComment: ITempComment | null = await localforage.getItem('tempComment')
            if (delivery.value && tempComment) {
                delivery.value.noteDelivery = tempComment.comment
            }

            const storageActiveAction: any = await localforage.getItem('deliveryActiveAction')
            if (storageActiveAction) {
                if (+storageActiveAction.currentDeliveryId === +route.params.id) {
                    setActiveAction(storageActiveAction.action)
                }
                localforage.removeItem('deliveryActiveAction')
            }
            checkIfSavedReturnsExists()
        })

        return {
            addComment,
            router,
            delivery,
            activeAction,
            productQuantity,
            setActiveAction,
            isEditActive,
            changeDeliveryStatus,
            changeProductAmount,
            EActions,
            isCourierNote,
            isAcceptDisabled,
            isLoadOrUnload,
            isLoading,
            isManagerNote,
            isWarehouseNote,
            EHeadings,
            showAmountSymbol,
            isDeliveryReturnsSaved,
            saveCurrentAction,
            checkIfSavedReturnsExists,
        }
    },
})
